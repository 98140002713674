import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import styled from 'styled-components';
import { isString } from 'lodash';

import { SiteLocale } from '../types';
import baseTheme from '../lib/theme';

type ColorOption = 'default' | 'black' | 'green';
export interface LinkProps {
  href?: string;
  ref?: any;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  nextLocale?: SiteLocale;
  target?: '_blank' | '_self';
  onClick?: (e: any) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  title?: string;
  rel?: string;
  inline?: boolean;
  emphasis?: boolean;
  underline?: boolean;
  color?: ColorOption;
}

const linkColor: Record<ColorOption, string> = {
  default: '',
  black: baseTheme.colors.primaryColor,
  green: baseTheme.colors.link.color,
};

// pretty loose way to check this, but it should do the trick. Asset urls take protocol relatively, so check for //
const isFullUrl = (s: string): boolean =>
  Boolean(
    s &&
      (/(http(s?)):\/\//i.test(s) ||
        !s.indexOf('www.') ||
        !s.indexOf('//') ||
        !s.indexOf('mailto:')),
  );

const isSelfUrl = (s: string): boolean => Boolean(s.includes('alvarpet.com'));

const LinkStyle = styled.span(
  ({
    inline = false,
    color = 'default',
    underline = false,
    emphasis = false,
  }: Pick<LinkProps, 'inline' | 'color' | 'emphasis' | 'underline'>) => `
  color: ${linkColor[color]};
  text-decoration: ${underline ? 'underline' : 'none'};
  font-weight: ${emphasis ? '600' : '400'};
  ${inline ? 'font: inherit' : ''};

  &[aria-current='page'] {
    pointer-events: none;
    cursor: default;
  }
`,
);

const Link = ({
  children,
  href,
  disabled,
  nextLocale,
  ...props
}: LinkProps) => {
  const { query } = useRouter();
  let locale = isString(query.locale) ? query.locale : SiteLocale.EN;

  if (!href) {
    return <span {...props}>{children}</span>;
  }

  const autoProps: any = {};
  const nextLinkProps: NextLinkProps = { locale, href };
  if (isFullUrl(href)) {
    autoProps.target = '_blank';
    if (!isSelfUrl(href)) {
      nextLinkProps.prefetch = false;
      autoProps.rel = 'noopener nofollow';
    }
  } else {
    if (nextLocale) {
      const hrefLocaleSegment =
        href === `/${locale}` || href.startsWith(`/${locale}?`)
          ? `/${locale}`
          : `/${locale}/`;

      href = href.replace(hrefLocaleSegment, '/');
      locale = nextLocale;
    }

    if (!nextLinkProps.href.toString().startsWith(`/${locale}`)) {
      nextLinkProps.href = `/${locale}${href}`;
    }
  }

  if (disabled) {
    nextLinkProps.href = '#';
  }

  return (
    <NextLink {...nextLinkProps} passHref>
      <LinkStyle {...autoProps} {...props}>
        {children}
      </LinkStyle>
    </NextLink>
  );
};

export default Link;
